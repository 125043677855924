.admin-template {
	min-height: 100vh;
	background-color: #F5F5F5;

	.site-layout {
		margin-left: $menu-expanded;
		transition: all 0.2s;

		.nav-bar {
			margin-left: $menu-expanded;
			transition: all 0.2s;
			z-index: 2;
		}

		&.collapsed {
			margin-left: $menu-collapsed;

			.nav-bar {
				margin-left: $menu-collapsed;
			}
		}

		.content-main {
			background-color: #ffffff;
		}
	}

	.slider-container-menu {
		overflow: auto;
		height: 100vh;
		position: fixed;
		left: 0;
		background-color: $menu-background;

		.menu-footer {
			position: fixed;
			bottom: 0;
			width: $menu-expanded;
			padding-bottom: 25px;
			transition: all 0.3s;

			.logo-post {
				width: 38px;
				transition: all 0.3s;
			}

			.menu-footer-text {
				color: #685D83;
				transition: all 0.3s;
			}

			.logo-tdt1 {
				width: 33px;
				transition: all 0.3s;
			}

			.logo-tdt2 {
				transition: all 0.3s;
				display: none;
			}

			&.collapsed {
				width: $menu-collapsed;

				.logo-post, .menu-footer-text, .logo-tdt1 {
					display: none;
					transition: all 0.3s;
				}

				.logo-tdt2 {
					width: 17px;
					display: inline-block;
				}
			}
		}
	}

	.btn-collapsed-menu {
		position: relative;
		height: 46px;

		.anticon {
			cursor: pointer;
			color: #ffffff;
			font-size: 16px;
			line-height: 48px;
			position: absolute;
			right: 16px;
			height: 46px;
			z-index: 2;
			transition: all 0.3s;
		}
	}
}
