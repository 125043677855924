@use "sass:math";

$primary-color: #83d5d5;

$font-family-segoe: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important !default;

$text-transform-lowercase: lowercase !important;
$text-transform-uppercase: uppercase !important;
$text-transform-capitalize: capitalize !important;

$h1-font-size: 1.5rem !important; //24px
$h2-font-size: 1.25rem !important; //20px
$h3-font-size: 1.125rem !important; //18px
$h4-font-size: 1rem !important; //16px
$h5-font-size: 0.875rem !important; //14px
$h6-font-size: 0.75rem !important; //12px

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$font-family-base: $font-family-segoe;
$font-size-base: $h5-font-size !default;
$font-weight-base: $font-weight-normal !default;


$box-shadow-color: #00000029;
$backdrop: #00000063;
$deep-purple: #13023e;
$deep-purple-hover: #423464;
$black: #000000;
$hover-menu: #fafafa;
$light-gray: #e8e8e8;
$gray: #8c8c8c;
$disabled-border-color: #8c8c8c;
$gray-dark: #262626;
$gray-dark-2: #595959;
$gray-hover: #bfbfbf;
$account-gray: #f0f2f5;
$account-gray-hover: #ebeff5;
$account-gray-border: #e1e9f5;
$preview-border: #d9d9d9;

@function calculateRem($size) {
	$remSize: math.div($size, 16px);
	@return #{$remSize}rem;
}

$menu-collapsed: 48px;
$menu-expanded: 258px;
$menu-background: #13023E;
$menu-background-sub: #13023E;
$menu-item-selected: #66CCCC;
$menu-item-selected-mark: #7EFFFF;
$menu-item-hover: #66CCCC;

$preview-border: #d9d9d9;
