.show-filters-tags {
	display: flex;
	max-width: 100%;
	width: 100%;
	overflow-y: auto;

	.show-tags {
		display: flex;
		overflow: hidden;
		width: 100%;

		.filter-tag {
			border-radius: 11px;
		}
	}
}
