.remove-asterisk {
	.ant-form-item-label
	> label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
		display: none;
	}
}

.alert-styles {
	.ant-alert-icon {
		margin-right: calculateRem(10px);
		font-size: $h5-font-size;
	}

	.ant-alert-message {
		font-size: $h6-font-size;
		color: #595959;
	}
}

.ant-layout {
	background: #ffffff;
	overflow: hidden;

	.ant-layout-sider {
		background: transparent;
	}

	.ant-layout-header {
		background: transparent;
		height: auto;
		line-height: normal;
	}
}

.ant-layout {
	background-color: transparent;
}

.ant-menu-submenu.ant-menu-submenu-popup.ant-menu.ant-menu-dark.ant-menu-submenu-placement-rightTop {
	.ant-menu.ant-menu-sub.ant-menu-vertical {
		background-color: $menu-background;
	}
}

.ant-menu {
	&.main-menu, &.ant-menu-submenu.ant-menu-submenu-popup.ant-menu-dark.ant-menu-submenu-placement-rightTop {
		background-color: $menu-background;
		color: #ffffff;

		.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
			background-color: $menu-background !important;
		}

		.ant-menu-item {
			color: #ffffff;

			&:hover {
				background-color: $menu-item-hover;

				.ant-menu-title-content, .ant-menu-item-icon {
					color: $menu-background;
				}
			}
		}

		.ant-menu-item-selected {
			background-color: $menu-item-selected !important;

			&:before {
				width: 4px;
				height: 100%;
				background-color: $menu-item-selected-mark;
				content: "";
				position: absolute;
				left: 0;
				border-radius: 0 4px 4px 0;
				transition: border-color 0.3s, background 0.3s, padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
			}

			.ant-menu-title-content, .ant-menu-item-icon {
				color: $menu-background;
			}
		}
	}

	&.main-menu {
		.ant-menu-submenu {
			.ant-menu-submenu-title {
				&:hover {
					background-color: $menu-item-hover;

					.ant-menu-title-content, .ant-menu-item-icon {
						color: $menu-background;
					}

					.ant-menu-submenu-arrow {
						&:before, &:after {
							color: $menu-background;
							background-color: $menu-background;;
						}
					}
				}
			}
		}

		.ant-menu-sub {
			color: $menu-background;
		}
	}
}

.ant-tooltip.ant-menu-inline-collapsed-tooltip.ant-tooltip-placement-right {
	.ant-tooltip-content {
		.ant-tooltip-arrow {
			.ant-tooltip-arrow-content {
				background-color: $menu-background-sub;
			}
		}

		.ant-tooltip-inner {
			background-color: $menu-background;
		}
	}
}

.custom-columns-transfer {
	.ant-transfer-list {
		width: 47%;
		height: 256px;
	}
}

.ant-modal-title {
	font-weight: $font-weight-semi-bold;
}

.ant-modal.custom-alert-modal {
	width: 450px !important;
}

.ant-modal {
	.ant-modal-content {
		.ant-modal-body {
			height: auto !important;
		}
	}
}
