.asset-details {
	.ant-drawer-content-wrapper {
		.ant-drawer-content {
			.ant-drawer-header-no-title {
				.ant-drawer-close {
					z-index: 2001;
				}
			}

			.ant-drawer-wrapper-body {
				.ant-drawer-header {
					height: 0;
					padding: 0;
				}

				.ant-drawer-body {
					padding: 0;
					color: rgba(0, 0, 0, 0.65);

					.gray {
						color: rgba(26, 26, 26, 0.65);
					}

					.light-grey {
						color: rgba(26, 26, 26, 0.45);
					}
				}
			}
		}
	}
}
