.form-login {
  .title-form {
    font-size: $h2-font-size;
    font-weight: $font-weight-semi-bold;
  }

  .subtitle-form {
    font-size: $h4-font-size;
  }
}
