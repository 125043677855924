::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 10px;
}

::-webkit-scrollbar-button:increment,
::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar:horizontal {
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #8E8E93;
  border-radius: 20px;
  border: 1px, solid, #F5F5F5;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}