.nav-bar {
	height: 48px;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	box-shadow: 0 3px 6px #00000029;
	background-color: #ffffff;
	z-index: 1001;

	.ant-col {
		display: flex;
		min-width: 40px;

		&.item-center {
			align-items: center;
			justify-content: center;
		}

		&.hover {
			&:hover {
				background-color: #fafafa;
			}
		}

		.logo {
			width: 62px;
		}

		.icon-user {
			background-color: $primary-color;
			border-radius: 50%;
			padding: 2px;
		}
	}
}
