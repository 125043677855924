.asset-main {
	.container-filters {
		border-right: 1px solid #E8E8E8;
	}

	.btn-collapse-filters {
		position: absolute;
		top: 240px;
		right: -24px;
		height: 32px;
		z-index: 1;

		.anticon {
			transition: transform 0.3s;
		}

		&.icon-rotate {
			.anticon {
				transform: rotate(180deg);
			}
		}
	}

	.show-filters {
		width: 100%;
		display: flex;
		align-items: center;

		.showing-existing-supports {
			white-space: nowrap;
		}
	}
}
