body {
  font: {
    family: $font-family-base;
    size: $font-size-base;
    weight: $font-weight-base;
  }

  .cursor,
  .pointer {
    cursor: pointer;
  }

  .text-right {
    text-align: right;
  }

  .text-center {
    text-align: center;
  }

  .position-relative {
    position: relative;
  }

  @for $i from 0 through 100 {
    //positivos
    .m-l-#{$i} {
      margin-left: #{$i}px !important;
    }
    .m-r-#{$i} {
      margin-right: #{$i}px !important;
    }
    .m-t-#{$i} {
      margin-top: #{$i}px !important;
    }
    .m-b-#{$i} {
      margin-bottom: #{$i}px !important;
    }
    .m-#{$i} {
      margin: #{$i}px;
    }
    .m-#{$i}i {
      margin: #{$i}px !important;
    }
    .m-lr-#{$i} {
      margin: 0 #{$i}px 0 #{$i}px;
    }
    .m-tb-#{$i} {
      margin: #{$i}px 0 #{$i}px 0;
    }

    //negativos
    .m-l--#{$i} {
      margin-left: -#{$i}px !important;
    }
    .m-r--#{$i} {
      margin-right: -#{$i}px !important;
    }
    .m-t--#{$i} {
      margin-top: -#{$i}px !important;
    }
    .m-b--#{$i} {
      margin-bottom: -#{$i}px !important;
    }
    .m--#{$i} {
      margin: -#{$i}px;
    }

    //paddings
    .p-l-#{$i} {
      padding-left: #{$i}px;
    }
    .p-r-#{$i} {
      padding-right: #{$i}px;
    }
    .p-t-#{$i} {
      padding-top: #{$i}px;
    }
    .p-b-#{$i} {
      padding-bottom: #{$i}px;
    }
    .p-l-#{$i}i {
      padding-left: #{$i}px !important;
    }
    .p-r-#{$i}i {
      padding-right: #{$i}px !important;
    }
    .p-t-#{$i}i {
      padding-top: #{$i}px !important;
    }
    .p-b-#{$i}i {
      padding-bottom: #{$i}px !important;
    }
    .p-#{$i} {
      padding: #{$i}px;
    }
    .p-#{$i}i {
      padding: #{$i}px !important;
    }
    .p-lr-#{$i} {
      padding: 0 #{$i}px 0 #{$i}px;
    }
    .p-tb-#{$i} {
      padding: #{$i}px 0 #{$i}px 0;
    }

		.lh-#{$i} {
			line-height: #{$i}px;
		}
  }

  @for $i from 8 through 40 {
    .fs-#{$i} {
      font-size: #{$i}px !important;
    }
  }

  .mlr-0 {
    margin-left: 0;
    margin-right: 0;
  }

	.fw-600{
		font-weight: 600;
	}
}
