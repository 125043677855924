#reference-map-modal {
	@include flexbox();
	gap: 16px;
	flex-direction: column;
	font-size: $h6-font-size;

	.block {
		border: 1px solid $preview-border;

		.header {
			border-bottom: 1px solid $preview-border;
			padding: 12px;
			font-weight: $font-weight-semi-bold;
		}

		.body {
			padding: 12px;
		}

		.body.assets {
			@include flexbox();
			flex-direction: column;
			gap: 20px;

			.material {
				@include flexbox();
				gap: 15px;

				.digital,
				.impreso {
					@include flexbox();
					flex-direction: column;
					gap: 5px;
				}

				.title {
					font-weight: $font-weight-semi-bold;
				}

				.material-body {
					@include flexbox();
					flex-direction: column;
					gap: 5px;
				}

				.material-body div img {
					width: 12px;
					margin-right: 5px;
				}

				.separador {
					border-left: 1px solid $light-gray;
				}
			}

			.type {
				.type-title {
					font-weight: $font-weight-semi-bold;
				}

				.type-body {
					@include flexbox();
					@include justify-content(space-between);
				}

				.type-box .value {
					font-weight: $font-weight-semi-bold;
				}

				.separador {
					border-left: 1px solid $light-gray;
				}
			}
		}

		.body.poi {
			@include flexbox();
			flex-wrap: wrap;
			gap: 10px;

			.poi-item {
				@include flexbox();
				@include align-items(center);
				gap: 5px;
				width: 140px;

				.icon {
					@include flexbox();
					@include justify-content(center);
					@include align-items(center);
				}
			}
		}

		.body.ots {
			color: $gray;

			.scale {
				@include flexbox();
				@include justify-content(center);
				@include align-items(center);
			}
		}

		.body.example {
			.title {
				font-weight: $font-weight-semi-bold;
				margin-bottom: 12px;
			}

			.container {
				@include flexbox();
				@include align-items(center);
				gap: 5px;
			}
		}
	}
}
