.template-login {
	min-height: 100vh;

	.container-background {
		background-image: url("../img/login/background.jpg");
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		height: 100vh;
		display: flex;
	}

	.logo {
		height: 30px;
	}

	//.title-form {
	//	font-size: 18px;
	//}

	//.link-recover-password {
	//	color: $font-color-primary-text;
	//}

	.container-main{
		background-color: rgba(255, 255, 255, 0.95);
		min-height: 100vh;
		width: 100%;
		//max-width: 350px;

	}
}
