.spi-loading {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 20px;
	text-align: center;
	display: flex;
	z-index: 2000;
	background: rgba(255, 255, 255, 0.7);

	.ant-spin {
		margin: auto;
	}
}

.bg-white {
	background-color: #ffffff;
}

.mapboxgl-ctrl-logo {
	display: none !important;
}
