.img-no-available {
	img {
		width: 100%;
		height: auto;
	}

	p {
		position: absolute;
		bottom: 35px;
		width: 100%;
		color: #a3a3a3;
		font-size: 20px
	}
}
